import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/about-layout.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Soy Diseñador y Desarrollador de Aplicaciones Web`}</h1>
    <p>{`Mi nombre es `}<em parentName="p">{`Juan Moisés Torrijos`}</em>{` y soy `}<strong parentName="p">{`diseñador y programador`}</strong>{` de `}<strong parentName="p">{`sitios y aplicaciones`}</strong>{` para la web.`}</p>
    <p>{`Vivo en la `}<em parentName="p">{`Ciudad de Panamá`}</em>{` 🇵🇦 trabajando en una empresa `}<a parentName="p" {...{
        "href": "https://intermaritime.org/"
      }}>{`marítima internacional`}</a>{` como encargado de tecnología. Soy el fundador de una `}<a parentName="p" {...{
        "href": "https://digiartes.com"
      }}>{`empresa de diseño y desarrollo de sitios y aplicaciones web y móviles`}</a>{`. En mi tiempo libre escribo en mi blog, ayudo a empresas locales e internacionales a crear su presencia en la web, creo aplicaciones web y comparto mis conocimientos con mis colegas en diferentes `}<Link to="/acerca/comunidades" mdxType="Link">{`grupos locales de tecnología`}</Link>{`.`}</p>
    <div {...{
      "className": "right small-shadow small-border"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "685px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.49707602339183%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAW9n9Vk8Tw//xAAZEAACAwEAAAAAAAAAAAAAAAAAEgECExH/2gAIAQEAAQUCeo8SdqaDmh//xAAYEQADAQEAAAAAAAAAAAAAAAAAAlEREv/aAAgBAwEBPwHGhy0P/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAIT/9oACAECAQE/AaKUU//EABcQAQEBAQAAAAAAAAAAAAAAAAAyEDH/2gAIAQEABj8C2XEv/8QAHBABAAICAwEAAAAAAAAAAAAAAQARUWExQZGx/9oACAEBAAE/ITouAr+zcStOG4FTPcRfD2f/2gAMAwEAAgADAAAAEIQv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxAE/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAFBYbH/2gAIAQIBAT8QUjL9P//EAB0QAAMAAQUBAAAAAAAAAAAAAAABEVEhQXGRofD/2gAIAQEAAT8Qe1VcPZjjallSGLuFVyc/BWOYT+QVZWg//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ciudad de Panamá",
          "title": "Ciudad de Panamá",
          "src": "/static/2b44accaef756566eea3bab8d0e06316/0a2b8/ciudad-panama-pacifico.jpg",
          "srcSet": ["/static/2b44accaef756566eea3bab8d0e06316/0b054/ciudad-panama-pacifico.jpg 171w", "/static/2b44accaef756566eea3bab8d0e06316/ebfc4/ciudad-panama-pacifico.jpg 343w", "/static/2b44accaef756566eea3bab8d0e06316/0a2b8/ciudad-panama-pacifico.jpg 685w", "/static/2b44accaef756566eea3bab8d0e06316/ea522/ciudad-panama-pacifico.jpg 1028w", "/static/2b44accaef756566eea3bab8d0e06316/0d465/ciudad-panama-pacifico.jpg 1370w", "/static/2b44accaef756566eea3bab8d0e06316/0f98f/ciudad-panama-pacifico.jpg 1920w"],
          "sizes": "(max-width: 685px) 100vw, 685px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </div>
    <p>{`Empecé mi carrera en el 2009, creando sitios web personalizados para todo tipo de clientes. Desde clínicas especializadas, escuelas de artes marciales, tiendas locales y empresas de construcción. Hoy en día me dedico a compartir mis conocimientos, escribir sobre tecnología y seguir creando sitios web.`}</p>
    <p>{`Con mi experiencia he adquirido muchas vivencias de lado de empresarios y emprendedores, quienes me han inspirado y abierto la mente para lograr nuevas metas. Soy padre de dos bellas niñas y esposo de una hermosa y cariñosa mujer, quien me acompaña en mi peregrinaje profesional. Estudié Ingeniería en la Universidad Estatal de Louisiana (Louisiana State University) y mi bachillerato en el Colegio San Agustín.`}</p>
    <p>{`Si estás buscando un profesional con vasta experiencia en sitios y aplicaciones web contáctame. Estoy disponible para trabajar, me puedes `}<Link to="/contactame" mdxType="Link">{`contactar aquí`}</Link></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      